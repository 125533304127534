<template>
  <div>
    <AppBar
  :bar-title="barTitle"
  :user="user"
  :campaign-data="campaignData"
  :show-Switch-Campaign-Button="showSwitchCampaignButton"
  :user-team="userTeam"
  :isMobile="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdAndDown"
  :current-Path="$route.matched[0].path.substring(1)"
  @toggle-drawer="drawer = !drawer"
  @toggle-switch-campaign-modal="toggleSelectCampaignModal({ show: true })"
  @edit-campaign="editCampaign"
  @upload-documents="route1"
  @logout="logoutClickHandler"
/>

    
    <SideBar
    @reload="loadData"
  :drawer.sync="drawer"
  :navLinks="navLinks"
  :selectedItem="selectedItem"
  :isMobile="$vuetify.breakpoint.xsOnly || $vuetify.breakpoint.mdAndDown"
  :logo-src="orgLogoUrl"
  :organizationData="organizationData"
  :campaign-name="campaignData.name"
  :campaign-data="campaignData"
  @tab-change="ChangeTab"
/>
    <v-main style="height: auto; background: #f7f7f7">
      <router-view></router-view>
    </v-main>
    <select-campaign-modal @reload="loadData"></select-campaign-modal>
    <teacher-edit-modal @reload="relaodPage"></teacher-edit-modal>
    <update-district-modal @reload="relaodPage"></update-district-modal>
    <update-school-modal @reload="relaodPage"></update-school-modal>
  </div>
</template>

<script>
import { ROUTER_URL } from "@/constants/urls";
import authToken from "@/common/authToken";
import { mapActions, mapGetters } from "vuex";
import {
  SALES_REPRESENTATIVE,
  TEACHER,
  PRINCIPAL,
} from "@/constants/ModuleKeys";
import AppBar from "@/components/Dashboard/AppBar.vue"
import SideBar from "@/components/Dashboard/SideBar.vue"
import Helper from "@/helper";
import { CAMPAIGN_ID } from "@/constants/APIKeys";
// import _ from "lodash";
export default {
  name: "CampaignLayout",
  components: {
    AppBar,
    SideBar,
    SelectCampaignModal: () =>
      import("@/components/SelectCampaignModal/SelectCampaignModal.vue"),
    TeacherEditModal: () =>
      import("@/components/TeacherUpdateModal/TeacherUpdateModal"),
    UpdateDistrictModal: () =>
      import("@/components/DistrictUpdateModal/DistrictUpdateModal"),
    UpdateSchoolModal: () =>
      import("@/components/SchoolUpdateModal/SchoolUpdateModal"),
  },
  data() {
    return {
      
      subAdminAccess: localStorage.getItem("subadminAccess"),
      user: {},
      selectedItem: -1,
      navLinks: [
        {
          title: "School Info",
          icon: "team",
          heading: " Info",
          key: "todolist",
          permission: ["school"],
          to: "",
        },
        {
          title: "Onboarding",
          icon: "team",
          heading: "Fill Out School Info ",
          key: "hosttodolist",
          permission: ["school"],
          to: "",
        },
        {
          title: "Resources",
          icon: "sponsor",
          heading: "Resource Management",
          key: "resource",
          permission: ["school"],
          to: "",
        },
        {
          title: "Dashboard",
          icon: "dashboard",
          heading: " Campaign Dashboard",
          key: "dashboard",
          permission: [
            "district",
            "school",
            "teacher",
            "pe-teacher",
            "principal",
          ],
          to: "",
        },
        {
          title: "Schools",
          icon: "school",
          heading: "School Campaigns",
          key: "schoolManagement",
          permission: ["district"],
          to: "",
        },
        {
          title: "Teachers",
          icon: "teacher",
          heading: "Teacher Management",
          key: "teacherManagement",
          permission: ["district", "school"],
          to: "",
        },
        {
          title: "Students",
          icon: "student",
          heading: "Student Management",
          key: "studentManagement",
          permission: ["district", "school", "teacher"],
          to: "",
        },
        {
          title: "Sponsors",
          icon: "sponsor",
          heading: "Sponsor Management",
          key: "sponsorManagement",
          permission: ["district", "school"],
          to: "",
        },
        {
          title: "Donation",
          icon: "donation",
          heading: "Donation Management",
          key: "donationManagement",
          permission: ["district", "school"],
          to: "",
        },
        {
          title: "Committee",
          icon: "team",
          heading: "Committee Management",
          key: "teamManagement",
          permission: ["district", "school"],
          to: "",
        },
        {
          title: "Emails",
          icon: "email",
          heading: "Emails",
          key: "emailSettings",
          permission: ["district", "school"],
          to: "",
        },

        {
          title: "Gallery",
          icon: "team",
          heading: "Gallery Management",
          key: "galleryManagement",
          permission: ["teacher", "school"],
          to: "",
        },

        {
          title: "Calendar",
          icon: "calendars",
          heading: "Calendar Management",
          key: "campaigncalendar",
          permission: ["school"],
          to: "",
        },
        {
          title: "Need Help",
          icon: "email",
          heading: "Need Help",
          key: "needhelp",
          permission: ["school", "teacher", "district"],
          to: "",
        },
        /**     {
          title: "Game",
          icon: "campaign",
          heading: "CampaignManagement Management",
          key: "campaignGame",
          permission: ["school"],
          to: "",
        },*/
      ],
      drawer: false,
      barTitle: "Campaign Dashboard",
      selected: "Dashboard",
      districtData: {},
      campaignData: {},
      organizationData: {},
      campaignListLength: -1,
      loader: true,
    };
  },
  created() {
    this.$root.$refs.campaignLayout = this;
  },
  computed: {
    ...mapGetters({
      selectedCampaign: "campaign/getSelectedCampaign",
    }),
    orgLogoUrl() {
  if (this.organizationData && this.organizationData.logo_url) {
    console.log("logo");
    return this.organizationData.logo_url;
  }
  return require('@/assets/Auth/wizfilogo1.png'); // default fallback
},
    currentRouteName() {
      return this.$route.name;
    },
    userTeam() {
      return authToken.decodedToken().user_team;
    },
    selectedCampaignData() {
  return this.selectedCampaign || {};
}
,
    campaign() {
      return this.selectedCampaignData.campaignData;
    },
    showSwitchCampaignButton() {
      switch (this.$route.name) {
        case ROUTER_URL.districtPanel.children.dashboard.name:
          return true;
        case ROUTER_URL.schoolPanel.children.dashboard.name:
          return true;
        case ROUTER_URL.teacherPanel.children.dashboard.name:
          return true;
        default:
          return false;
      }
    },
    routeQuery() {
      return this.$route.query;
    },
    queryParams() {
      let item = {};
      let USER_PANEL = this.$route.matched[0].path.substring(1);
      if (this.$route.query[CAMPAIGN_ID])
        item[CAMPAIGN_ID] = this.$route.query[CAMPAIGN_ID];
      if (this.$route.query[USER_PANEL])
        item[USER_PANEL] = this.$route.query[USER_PANEL];
      else if (
        this.$route.matched[0].path.substring(1) === "pe-teacher" ||
        this.$route.matched[0].path.substring(1) === PRINCIPAL
      )
        item[TEACHER] = this.$route.query["teacher"];
      return item;
    },
  },
  watch: {
  selectedCampaignData: {
    immediate: true,
    handler(val) {
      if (val && val.campaignData) {
        this.loadData();
      }
    }
  },
    currentRouteName(val) {
      if (val === ROUTER_URL.schoolPanel.children.bulkUploadTeacher.name) {
        this.barTitle = "Upload Teacher in Bulk";
      }
    },
  },
  methods: {
    ...mapActions({
      toggleSelectCampaignModal: "campaign/toggleModal",
      selectCampaignAction: "campaign/updateSelectedCampaign",
      toggleAddEditViewTeacherModal: "teacherManagement/toggleTeacher",
    }),
    relaodPage() {
      this.$router.go();
    },
    getImgUrl(img) {
      return require("@/assets/Navigation/" + img);
    },
    route() {
      let linkToBeChange =
        ROUTER_URL.schoolPanel.children.teacherManagement.name;
      let NewLink = ROUTER_URL.schoolPanel.children.bulkUploadTeacher.name;
      let key = ROUTER_URL.schoolPanel.children.bulkUploadTeacher.name;
      let heading = "Upload Teacher in bulk";
      this.navLinksManipulation(linkToBeChange, NewLink, key, heading);
      this.$router.push({
        name: NewLink,
        query: this.queryParams,
      });
    },
    navLinksManipulation(linkToBeChange, NewLink, key, heading) {
      this.selectedItem = this.navLinks.findIndex(
        (item) => item.key === linkToBeChange
      );
      this.navLinks[this.selectedItem] = NewLink;
      // this.navLinks[this.selectedItem].key = key;
      // this.navLinks[this.selectedItem].heading = heading;
      console.log(key, heading);
    },
    /**
     * Logout button click handler
     */
    logoutClickHandler() {
      authToken.removeAccessToken();
      this.$router.push({ name: ROUTER_URL.auth.children.login.name });
      this.clearData();
    },
    /**
     * for changing the tabs and heading
     * param {string} title - navbar heading
     */
    ChangeTab(heading, select) {
      this.barTitle = heading;
      this.drawer = false;
      this.selected = select;
    },
    /**
     * function returns user's initials
     * param {string}  user's full name
     **/
    getInitials(name) {
      let initials = name.split(" ");
      if (initials.length > 1) {
        initials = initials.shift().charAt(0) + initials.pop().charAt(0);
      } else {
        initials = name.substring(0, 2);
      }
      return initials.toUpperCase();
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    setNavigationBarData(userType) {
      let USER_PANEL = Helper.getUserPanel(userType);
      //permission check and route
      this.navLinks = this.navLinks.filter((nav) => {
        if (nav.permission.includes(userType)) {
          let COMPONENT = nav.key;
          nav.to = ROUTER_URL[USER_PANEL].children[COMPONENT]["name"];
          return nav;
        }
      });
      //setting up Dashboard title
      this.navLinks[0].heading =
        this.capitalizeFirstLetter(userType) + this.navLinks[0].heading;
      this.ChangeTab(this.navLinks[0].heading, this.navLinks[0]);
      // if user is a SALES REP have to remove teams nav-link
      if (this.user.user_type === SALES_REPRESENTATIVE) {
        let removeLinks = ["teamManagement"];
        let newNavLinks = this.navLinks.filter(
          (nav) => !removeLinks.includes(nav.key)
        );
        this.navLinks = newNavLinks;
      }
      if (this.user.user_type === "game") {
        let removeLinks = ["todolist"];
        let newNavLinks = this.navLinks.filter(
          (nav) => !removeLinks.includes(nav.key)
        );
        this.navLinks = newNavLinks;
      }
      if (this.user.user_type === "school") {
        let removeLinks = ["todolist"];
        let newNavLinks = this.navLinks.filter(
          (nav) => !removeLinks.includes(nav.key)
        );
        this.navLinks = newNavLinks;
      }
      if (this.user.user_type === "district") {
        let removeLinks = ["todolist"];
        let newNavLinks = this.navLinks.filter(
          (nav) => !removeLinks.includes(nav.key)
        );
        this.navLinks = newNavLinks;
      }
    },
    loadData() {
      console.log("loadingdata....",this.campaignData)
      this.organizationData = this.selectedCampaignData.organizationData;
      this.campaignData = this.selectedCampaignData.campaignData;
      this.loader = false;
    },
    clearData() {
      this.selectCampaignAction({});
    },
    editCampaign() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.newCampaign.name,
        query: { type: "edit", id: this.selectedCampaignData.campaignData.id },
      });
    },
    viewPublicDonationPageHandler11(item) {
      const { sub_domain, campaign_type } = item;
      const id = this.organizationData.id;
      const PANEL = this.$route.matched[0].path.substring(1);
      const queryParam = PANEL + "=" + id;
      console.log(sub_domain);
      if (
        PANEL === "district" ||
        (PANEL === "school" && campaign_type === "school")
      ) {
        Helper.openPublicDonationPage(sub_domain);
      } else {
        Helper.openPublicDonationPage(sub_domain, queryParam);
      }
      // Helper.openPublicDonationPage(sub_domain,queryParam);
    },
    viewPublicDonationPageHandler(item) {
      const { sub_domain, campaign_type } = item;
      const id = this.organizationData.id;
      const PANEL = this.$route.matched[0].path.substring(1);
      let queryParam = "";

      // Only append queryParam when PANEL is not "school"
      if (PANEL !== "school") {
        queryParam = `${PANEL}=${id}`;
      }

      console.log(sub_domain);

      // Open the public donation page depending on the PANEL and campaign_type
      if (
        PANEL === "district" ||
        (PANEL === "school" && campaign_type === "school")
      ) {
        Helper.openPublicDonationPage(sub_domain);
      } else {
        Helper.openPublicDonationPage(sub_domain, queryParam);
      }
    },
    route1() {
      this.$router.push({
        name: ROUTER_URL.schoolPanel.children.resourceupload.name,
      });
      //this.$root.$refs.adminLayout.route("school");
    },
  },
  mounted() {
    
    this.user = { ...authToken.decodedToken() };
    this.setNavigationBarData(this.$route.matched[0].path.substring(1));
    let initials = this.getInitials(this.user.user_name);
    this.user = { ...this.user, initials };
    this.selectedItem = this.navLinks.findIndex(
      (item) => item.key === this.currentRouteName
    );
    if (
    this.selectedCampaignData &&
    this.selectedCampaignData.campaignData &&
    Object.keys(this.selectedCampaignData.campaignData).length
  ) {
    this.loadData();
    console.log("here 44444",this.campaignData);

  } else {
    this.toggleSelectCampaignModal({ show: true });
  }
    this.toggleSelectCampaignModal({ show: true });
  },
};
</script>

<style scoped>
.switchCampaignBtn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
}
.switchCampaignBtn >>> span {
  font-family: Lato;
  letter-spacing: 0px;
  text-transform: none;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 315%;
}
.mainToolbar-title {
  font-family: LubalGraph Bd BT;
  font-style: normal;
  font-weight: normal;
  font-size: 19px;
  line-height: 315%;
  color: #2d2d2d;
  white-space: nowrap;
}
.campaignStatus >>> span {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  line-height: 161.5%;
}

.ongoing {
  background: #fcede0 !important;
}
.ongoing >>> span {
  color: #e76f00 !important;
}
.upcoming {
  background-color: rgba(0, 133, 255, 0.12) !important;
}
.upcoming >>> span {
  color: #0085ff !important;
}
.completed {
  background-color: rgba(6, 118, 5, 0.12) !important;
}
.completed >>> span {
  color: #067605 !important;
}

.campaignId {
  font-family: Lato;
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 145%;
  color: rgba(29, 29, 29, 0.6);
}
.flex-zero {
  flex: none;
}
.display-inline {
  display: inline;
}
.font-roboto-slab {
  font-family: Roboto Slab !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 13px !important;
  line-height: 300% !important;
  color: #9387b6 !important;
}
.active-class {
  border-right: 6px solid #ffba00;
  width: 100%;
  border-radius: 0px !important;
}
.active-class >>> .v-list-item__title {
  color: #ffffff !important;
}
* >>> .theme--dark.v-list-item--active::before {
  opacity: 0.14;
  border-radius: 0px;
}
.user-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 17px;
  line-height: 315%;
  color: #ff5151;
  letter-spacing: 0px;
}
.orgLogo-initials-text {
  font-family: Lato;
  font-style: normal;
  font-weight: 800;
  font-size: 65px;
  line-height: 315%;
  color: #ff5151;
  letter-spacing: 0px;
}

.tabs {
  margin-left: 100px;
}
.schoolName {
  font-family: Roboto Slab;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 127%;
  color: #2c1963;
  cursor: pointer;
}
@media (min-width: 0px) and (max-width: 600px) {
  .tabs {
    margin-left: 0px;
  }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
}
</style>
